@primary: var(--mainTheme);
@primary-hover: #6993ff;
@primary-active: #2a4fd4;
@actionColor: #ff5501;
@main-color: #1f1f1f;
@content-color: #293d66;
@text-color: #4d5e80;
@main-text-color: #1f2e59;
@label-color: #4d5e8c;
@card-radius: 8px;
@primary-light-bg-hover: #E3EEFF;
@primary-light-hover: #F0F6FF;
@primary-border: #BAD2FF;
@primary-border-hover: #91B4FF;
@primary-backup1: #1A35AD;
@primary-backup2: #0E2087;
@primary-backup3: #091361;
@primary-text-color1: #02091F;
@primary-text-color2: #1F2C52;
@primary-text-color3: #4D5B85;
@primary-text-color4: #8F99B8;
@primary-border-color1: #D3DAF0;
@primary-border-color2: #EDF0F9;
@primary-background-color: #F2F5FC;
@primary-background-color1: #E3E9FC;
@primary-background-color2: #F0F3FE;
@primary-background-color3: #F7F9FF;






.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}
// float button component
.ant-float-btn-square {
  border-radius: 50%;
  .ant-float-btn-body {
    border-radius: 50%;
  }
}

// table component
.ant-table-wrapper {
  .ant-pagination {
    padding-right: 12px !important;
    margin: 16px 0 0 0 !important;
  }
}

.ant-table-cell {
  word-break: break-all;
}

.ant-pro-table-column-setting-overlay {
  .ant-popover-inner-content {
    width: 250px !important;
  }
}
.ant-pro-table-column-setting-list-item-title {
  max-width: none !important;
}

.ant-table-container {
  .ant-table-cell {
    font-size: 12px;
  }
  .ant-table-pagination .ant-pagination {
    margin-block-end: 0;
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-item {
    margin-bottom: 4px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.ant-table-thead {
  > tr {
    > th {
      height: 40px !important; // 设置全局表头的高度
    }
  }
}

.ant-table-cell {
  .ant-btn,
  .ant-btn-variant-link {
    font-size: 12px !important;
    line-height: 18px;
  }
}

.ant-table-wrapper {
  td.ant-table-column-sort {
    background-color: @primary-background-color3;
  }
  .ant-table {
    background-color: transparent;
    scrollbar-color: auto;
    .ant-table-container {
      border: 1px solid rgba(211, 218, 240, 0.3);
      border-radius: 8px;
      .ant-table-content {
        border-start-start-radius: 8px;
        border-start-end-radius: 8px;
      }
      .ant-table-tbody {
        background-color: #fff;
        border-radius: 8px;
        .ant-table-placeholder {
          border-radius: 8px;
          td {
            border-end-start-radius: 8px;
            border-end-end-radius: 8px;
          }
        }
        tr:last-child td {
          border-bottom: none;
        }
        .ant-table-expanded-row td {
          background-color: #f2f5fc;
        }
      }
    }
    .ant-table-content,
    .ant-table-body {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-end-start-radius: 6px !important;
        border-end-end-radius: 6px !important;
      }
      /* 滚动条轨道的样式 */
      &::-webkit-scrollbar-track {
        background: transparent !important; /* 轨道背景颜色 */
      }
      /* 滚动条滑块的样式 */
      &::-webkit-scrollbar-thumb {
        background: #dfe4f5;
        border-radius: 3px !important; /* 轨道圆角 */
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent !important;
      }
    }
  }
}

.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover {
  background-color: #f0f3fe !important;
}

.ant-layout {
  background-color: @primary-background-color !important;
}

.ant-typography {
  font-size: 12px;
  user-select: text !important;
  &:hover {
    color: @primary-hover;
  }
}

.ant-form-item-explain-error {
  height: 16px !important;
  line-height: 16px !important;
}

// tabs component
.ant-tabs {
  .ant-tabs-tab {
    color: #4d5e8c;
  }
}

.ant-tabs .ant-tabs-tab:hover {
  color: @primary;
}
.ant-tabs-tab-active {
  font-weight: 700 !important;
}

.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}
.ant-card {
  padding: 24px;
  border-radius: 12px;
  .ant-card-body {
    padding: 0;
  }
}

.ant-drawer *,
.ant-modal *,
.ant-popover *,
.ant-message *,
.ant-tooltip *,
.ant-select-dropdown *,
.ant-row *,
.ant-layout * {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
.max-tool-tip {
  .tool-tip-content {
    max-height: 200px;
    overflow-y: auto;
    max-width: 300px;
    padding: 0 8px;
    white-space: wrap;
    word-break: break-all;
  }
  .tool-tip-content::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f7f9fc;
  }
  .tool-tip-content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #f7f9fc;
  }
  .tool-tip-content:-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 12px;
    background-color: #b8c1d9;
  }
  .tool-tip-content:-webkit-scrollbar-thumb:hover {
    background-color: #b8c1d9;
  }
}

.ant-btn {
  &.ant-btn-default {
    border-color: @primary-border-color1;
  }
  &.ant-btn-variant-outlined:disabled {
    background-color: @primary-background-color;
    color: #8799b8;
  }
  &.ant-btn-variant-link:disabled {
    color: @primary-text-color4;
  }
  &.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      border-color: @primary;
      color: @primary;
    }
  }
}
.ant-dropdown-menu-item-selected {
  background-color: #e3eeff !important;
}
.ant-dropdown-menu-item:hover {
  &:not(.ant-dropdown-menu-item-selected) {
    background-color: @primary-background-color3 !important;
  }
}
.ant-select-tree-node-content-wrapper:not(.ant-select-tree-node-selected):hover {
  background-color: rgba(245, 245, 245, 1) !important;
}
.ant-select {
  .ant-select-arrow {
    color: @primary-border-color1 !important;
  }
  &:hover .ant-select-clear {
    background-color: #f0f3fe;
  }
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):not(.ant-input-group-addon > .ant-select):hover
  .ant-select-selector {
  border-color: #3f6ffb !important;
}
.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #e3eeff !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @primary-background-color3;
  }
}
.ant-picker-outlined {
  border-color: @primary-border-color1;
  .ant-picker-input > input {
    &::placeholder {
      color: #b8c2e0;
    }
  }
  .ant-picker-separator,
  .ant-picker-suffix {
    color: @primary-border-color1;
  }
}

.ant-input::placeholder {
  color: #b8c2e0;
}
.ant-input-outlined {
  border-color: @primary-border-color1;
  // border-radius: 6px;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  border-color: @primary-border-color1;
  background-color: @primary-background-color;
  color: #b8c2e0;
}
.ant-input-group-wrapper-outlined {
  .ant-input-group-addon {
    border-color: @primary-border-color1 !important;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      color: @primary-text-color1 !important;
    }
  }
}
.ant-input-group {
  .ant-input-group-addon {
    border: node !important;
    // border-radius: 6px 0px 0px 6px ;
    &:first-child {
      border-inline-start: 0;
    }
    .ant-select.ant-select-single:not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      ) {
      .ant-select-selector {
        background-color: @primary-background-color3;
        border: 1px solid @primary-border-color1;
        border-right: none;
        color: #8f99b8;
        // border-radius: 6px 0px 0px 6px !important;
      }
    }
  }
}
.ant-tree {
  .ant-tree-checkbox {
    .ant-tree-checkbox-inner {
      border-color: @primary-border-color1;
    }
  }
}
.ant-select-selector:not(.ant-input-group-addon .ant-select-selector) {
  border-color: @primary-border-color1;
}
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  background-color: @primary-background-color;
}
.ant-select-selection-placeholder {
  color: #b8c2e0 !important;
}
.ant-alert-success {
  background-color: #e9f9f6 !important;
  border-color: #8fdfd1 !important;
  .ant-alert-success > .ant-alert-icon {
    color: #1fbfa2 !important;
  }
}
.ant-alert-error {
  background-color: #ffeded !important;
  border-color: #ffa6a6 !important;
  .ant-alert-error > .ant-alert-icon {
    color: #ff4d4d !important;
  }
}
.ant-alert-warning {
  background-color: #fff8e9 !important;
  border-color: #ffb525 !important;
  .ant-alert-warning > .ant-alert-icon {
    color: #ffb525 !important;
  }
}
.ant-alert-info {
  background-color: #f0f6ff !important;
  border-color: #3f6ffb !important;
  .ant-alert-info > .ant-alert-icon {
    color: #3f6ffb !important;
  }
}
.ant-message-success > .anticon {
  color: #1fbfa2 !important;
}
.ant-message-error > .anticon {
  color: #ff4d4d !important;
}
.ant-message-warning > .anticon {
  color: #ffb525 !important;
}
.ant-message-info > .anticon {
  color: #3f6ffb !important;
}
.ant-collapse-item {
  // border-radius: 12px !important;
  > .ant-collapse-header {
    padding: 16px 16px 12px 16px !important;
  }
  > .ant-collapse-content {
    > .ant-collapse-content-box {
      padding: 4px 16px 16px 16px !important;
    }
  }
}

.ant-empty {
  .ant-empty-description {
    color: #8f99b8;
  }
}
.ant-drawer .ant-drawer-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  background-color: @primary;
}

.ant-tag {
  border-color: @primary-border-color1;
  &.ant-tag-checkable-checked:hover {
    background-color: @primary-hover !important;
  }
}

.ant-checkbox .ant-checkbox-inner {
  // border-radius: 6px;
  border-color: @primary-border-color1;
}
.ant-upload-wrapper .ant-upload-drag {
  background-color: @primary-background-color3;
  p.ant-upload-hint {
    color: @primary-text-color3;
  }
}

.ant-tree-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color: #e3eeff !important;
    }
    .ant-select-tree-node-content-wrapper:not(.ant-select-tree-node-selected):hover {
      background-color: @primary-background-color3 !important;
    }
    .ant-select-tree-treenode:not(.ant-select-tree-treenode-disabled).filter-node
      .ant-select-tree-title {
      color: #000 !important;
    }
  }
}
